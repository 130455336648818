<template lang="pug">
.v-add-edit-asset-liability(v-if='isLoggedIn')
  .heading-1
    | {{addEditStr}} {{ moneyText }}{{scenarioText}}
  AddEditForm(:fields='fields' :fieldValues='fieldValues' :adding='addingAsset' @dataAdded="dataAdded" @cancelled="cancelled" @dataEdited="dataEdited" :addText='addText' :disableSave='formIsOpen' focusName="Asset")
    ChangesForm(:fields='changeFields' :fieldValues='changeFieldValues' :moneyType='moneyText' :disableAdd='formIsOpen' @postChanges='postAssetChanges' @formOpen="formOpen" @formClose="formClose")
    AssetIncomeExpense(:moneyType='moneyText' :income='true' :monies='incomes' :scenarioId='scenarioId' :disableAdd='formIsOpen' @postMonies='postIncomes' @formOpen="formOpen" @formClose="formClose")
    AssetIncomeExpense(:moneyType='moneyText' :income='false' :monies='expenses' :scenarioId='scenarioId' :disableAdd='formIsOpen' @postMonies='postExpenses' @formOpen="formOpen" @formClose="formClose")
  MoneyBar
</template>

<script>
import axios from "axios";
import AddEditForm from "../components/addEditForm.vue"
import FieldListsMixin from "@/components/fieldListsMixin.vue";
import ChangesForm from "@/components/changesForm.vue";
import AssetIncomeExpense from "@/assets/assetIncomeExpense.vue";
import { clone } from "@/common/copies"
import PermissionsMixin from "@/components/permissionsMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [FieldListsMixin, PermissionsMixin],
  // props: ['moneyType'],
  name: 'addEditAssetLiability',
  data() {
    return {
      changes: undefined,
      incomes: undefined,
      incomeChanges: [],
      incomeValues: [],
      expenses: undefined,
      expenseChanges: [],
      expenseValues: [],
      fieldValues: {},
      changeFieldValues: [],
      formIsOpen: false
    }
  },
  computed: {
    fields: function () { return [
      {name: 'name', label: 'Name', type: 'string', mandatory: true},
      {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date you wish to start the "+this.moneyText+" value. For a bank account you could use todays date, or a date in the past."},
      {name: 'end_date', label: 'End Date', type: 'date', help_text: "Typically there is no end date for "+this.moneyTextLowerPlural+", unless you are closing an account or selling a "+this.moneyTextLower},
      {name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "The balance or value of the "+this.moneyTextLower+" on the given Start Date."},
      {name: 'description', label: 'Description', type: 'text'},
      {name: 'appreciation', label: 'Appreciation', type: 'number',
        help_text: this.isAsset ?
            "Percent amount the "+this.moneyTextLower+" changes in value over 1 year. A house price will increase in value (eg 7%), a car will decrease in value (negative value, eg -3%), bank account will not change." :
            "Percent amount the "+this.moneyTextLower+" changes in value over 1 year. Typically a loan or mortgage will not change in value, so leave blank."},
      {name: 'accessible', label: 'Accessible', type: 'boolean', help_text: "If false, then any generated income or funds transferred to this "+this.moneyText+" will not be accessible, meaning this will not be considered spare money. If true, then any generated income or funds transferred to this "+this.moneyText+" will be considered spare money that can be spent elsewhere."}
    ]},
    changeFields: function () { return [
      {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the change in appreciation will happen"},
      {name: 'appreciation', label: 'Appreciation', type: 'number', mandatory: true, help_text: "The new appreciation value for the "+this.moneyTextLower},
    ]},
    isAsset: function () { return this.$route.name === 'EditAsset' || this.$route.name === 'AddAsset'},
    isLiability: function () { return this.moneyType === 'EditLiability' || this.$route.name === 'AddLiability' },
    moneyText: function () { return this.isAsset ? "Asset" : "Liability" },
    moneyTextLower: function () { return this.moneyText.toLowerCase() },
    moneyTextLowerPlural: function () { return this.isAsset ? "assets" : "liabilities" },
    addText: function () { return "Add "+this.moneyText },
    assetId: function () { return this.$route.params.id },
    scenarioId: function () { let n = this.$route.query.scenario_id; return n ? Number(n) : undefined },
    scenarioText: function () { return this.scenarioId ? " for Scenario" : "" },
    addingAsset: function () { return (this.assetId === undefined) },
    addEditStr: function () { return (this.addingAsset ? "Add" : "Edit") },
    baseUrl: function () { return "/api/"+this.moneyTextLowerPlural},
  },
  methods: {
    returnPageUrl: function (id) {
      return this.scenarioId ?
          "/addEditScenario/"+this.scenarioId+"?compEdited="+this.moneyText+"_"+id : "/"+this.moneyTextLowerPlural},
    prepareData: function (model) {
      let mcs = this.changes
      let data = {incomes: this.incomes, expenses: this.expenses}
      data[this.moneyTextLower] = clone(model)
      data[this.moneyTextLower]['money_changes'] = mcs
      data['money_changes'] = mcs
      if (this.scenarioId) {
        data[this.moneyTextLower]['scenario_id'] = this.scenarioId
        // All the money changes will include the scenario_id
        for (var index in mcs) {
          mcs[index]['scenario_id'] = this.scenarioId
        }
      }
      return data
    },
    dataAdded: function (model) {
      axios.post(this.baseUrl, this.prepareData(model)).then((response) => {
        this.$router.push(this.returnPageUrl(response.data.id));
      });
    },
    cancelled: function () {
      this.$router.push(this.returnPageUrl(this.assetId || ''));
    },
    dataEdited: function (model) {
      axios.put(this.baseUrl+"/"+this.assetId,this.prepareData(model)).then((response) => {
        this.$router.push(this.returnPageUrl(response.data.id));
      });
    },
    postAssetChanges: function (newChanges) {
      this.changes = newChanges
    },
    postIncomes: function (newIncomes) {
      this.incomes = newIncomes
    },
    postExpenses: function (newExpenses) {
      this.expenses = newExpenses
    },
    postIncomeChanges: function (newIncomeChanges) {
      this.incomeChanges = newIncomeChanges
    },
    postExpenseChanges: function (newExpenseChanges) {
      this.expenseChanges = newExpenseChanges
    },
    loadAsset: function (assetId) {
      axios.get(this.baseUrl+"/"+assetId).then((response) => {
        this.asset = response.data
        this.fieldValues = {
          // id: assetId,
          name: this.asset['name'],
          start_date: this.asset['start_date'],
          end_date: this.asset['end_date'],
          amount: this.asset['amount'],
          description: this.asset['description'],
          appreciation: this.asset['appreciation'],
          accessible: this.asset['accessible']
        }
        this.changeFieldValues = this.asset['money_changes']
        this.changes = []
        for (let i=0; i<this.changeFieldValues.length; i++) {
          this.changes.push(this.changeFieldValues[i].money_changes)
        }
        this.incomes = clone(this.asset['incomes'])
        this.expenses = clone(this.asset['expenses'])
      });
    },
    formOpen: function () {
      this.formIsOpen = true
    },
    formClose: function () {
      this.formIsOpen = false
    }
  },
  mounted: function () {
    if (this.assetId !== undefined) {
      this.loadAsset(this.assetId)
    }
  },
  components: {
    AddEditForm,
    ChangesForm,
    AssetIncomeExpense,
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>