<template lang="pug">
.v-asset-balances-report(v-if='isLoggedIn')
  .heading-1
    | Asset Balances Report

  .report_div.pad-t-10(v-if='!hasChartData' )
    | This report depends on the Future Forecast Report
  .report_div.pad-t-10(v-if='hasChartData' )
    p
      | This report depends on the Future Forecast Report. The balances of each asset for a given scenario and year.
      | Select a Scenario, 3 months or 1 year, and a time range. You can hide/show a chart line by clicking on the Asset name in the chart.

    .report-row.pad-t-20
      .form__input.pad-r-50
        select(v-model='scenarioId' @change='scenarioChange')
          option(v-for='scenario in scenarios' :value='scenario.id')
            | {{ scenario.name }}
      .form__button_group
        button.small_button.button__fitted.pad-l-10.pad-r-10(@click="setMonths(3)" :class="{'button__empty' : months != 3}") 3m
        button.small_button.button__fitted.pad-l-10.pad-r-10(@click="setMonths(12)" :class="{'button__empty' : months != 12}") 1y
      .currdate-div
        button.small_button.button__fitted.button__gray(@click="moveDir('left')") <
        | {{ currDate }}
        button.small_button.button__fitted.button__gray(@click="moveDir('right')") >
        button.small_button.button__fitted.button__gray.mgn-l-50(@click="reset()")
          font-awesome(icon="redo")
  .report_div.pad-t-10(v-if='chartData' )
    .chart-container
      Line(:data='chartData' :options='chartOptions' ref='line')
  .report_div.pad-t-10(v-else )
    p
      | You must generate the Future Forecast report before you can see this report.

  MoneyBar
</template>

<script>

import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'assetBalancesReport.vue',
  data() {
    return {
      scenarioId: undefined,
      yearNum: 0,
      months: 12,
      move: 'right',
      firstDate: undefined,
      scenarios: [],

      labels: [],
      datasets: [],

      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'right'
          }
        },
        elements: {
          point: {
            radius: 1,
            hitRadius: 10,
          }
        }
      }

    }
  },
  computed: {
    currDate: function () {
      return moment(this.firstDate).format("MMM YYYY")
    },
    chartData: function () {
      return {
        labels: this.labels,
        datasets: [...this.datasets]
      }
    },
    hasScenarios: function () {
      return (this.scenarios !== undefined && this.scenarios.length > 0)
    },
    hasChartData: function () {
      return (this.chartData.datasets !== undefined && this.chartData.datasets.length > 0)
    }
  },
  methods: {
    loadAll: function () {
      axios.get("/api/scenarios").then((response) => {
        this.scenarios = response.data
        if (this.hasScenarios) {
          this.scenarioId = this.scenarios[0].id
          this.loadChart()
        }
      })
    },
    loadChart: function (move) {
      const dt = this.firstDate ? this.firstDate.toISOString() : ''
      axios.get("/api/reports/asset_balances_chart_data?scenario_id="+this.scenarioId+"&start_date="+dt+"&months="+this.months).then((response) => {
        const newChartData = response.data
        this.labels = newChartData.labels;
        this.datasets = newChartData.datasets;
        this.firstDate = moment.utc(this.labels[0], "DD MMM YY")
      })
    },
    scenarioChange: function () {
      this.loadChart()
    },
    setMonths: function (months) {
      this.months = months;
      this.loadChart()
    },
    moveDir: function (move) {
      this.move = move
      var mult = 1
      if (move === 'left') {
        mult = -1
      }
      this.firstDate = moment(this.firstDate).add(mult * this.months, 'months')
      this.loadChart()
    },
    reset: function () {
      this.months = 12;
      this.firstDate = undefined;
      this.loadChart()
    },
  },
  mounted: function () {
    this.loadAll()
  },
  components: {
    Line,
    MoneyBar
  }
}
</script>

<style lang="scss">
.v-asset-balances-report {
  .report-row {
    display: flex;
    align-items: center;
  }
  .report-div {
    display: flex;
    width: 100%;
  }
  .form__input {
    width: fit-content;
    select {
      width: auto;
    }
  }
  .currdate-div {
    display: flex;
    margin-left: 50px;
    gap: 15px;
    align-items: center;
    button {
      padding-top: 1px;
      padding-bottom: 3px;
      border-radius: 8px;
    }
  }
  .chart-container {
  }
}
</style>