<template lang="pug">
.v-week-panel
  .dashboard-panel__heading
    span What is happening this week?
  .week-panel-row
    .week-day-div(v-for='day,index in weekDays()' :class='weekDayClass(index)')
      .week-day-date(v-html='day')
      .event-items(v-if='weekEvents')
        .event-item(v-for='evt in weekEvents[index]')
          .event-container(:class='eventClass(evt)')
            .event-row1
              span {{ evt['name']}}
              span {{ formatCurrency(evt['amount']) }}
            .event-row2 {{ eventRow2(evt) }}
</template>

<script>
import moment from "moment";
import axios from "axios";
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [CommonMixin],
  name: 'weekPanel.vue',
  data() {
    return {
      startDate: undefined,
      weekEvents: undefined
    }
  },
  computed: {
  },
  methods: {
    wkEvents: function (index) {
      return this.weekEvents ? this.weekEvents[index].events : [];
    },
    weekDays: function () {
      var days = []
      if (this.startDate === undefined) { return days }
      var currDay = moment(this.startDate)
      for (var i = 1; i <= 7; i++) {
        days.push(currDay.format('MMM D') + "<br>" + currDay.format('dddd'));
        currDay = currDay.add(1, 'day');
      }
      return days;
    },
    dayEvents: function (day) {
      if (this.weekEvents === undefined) {
        return []
      }
      return this.weekEvents[day]
    },
    eventClass: function (event) {
      if (event === undefined) return '';
      let type = event.type
      return "money-event event-"+type
    },
    weekDayClass: function (index) {
      return "week-day-"+index;
    },
    eventRow2: function (evt) {
      if (evt.type === 'transfer') {
        return (evt.from_asset || evt.from_liability) + " -> " + (evt.to_asset || evt.to_liability)
      } else {
        return evt.asset || evt.liability
      }
    }
  },
  mounted: function () {
    axios.get("/api/reports/week_events").then((response) => {
      this.startDate = response.data.start_date;
      this.weekEvents = response.data.data;
    })

  },
  components: {}
}
</script>

<style lang="scss">
.v-week-panel {
  .week-panel-row {
    display: flex;
    margin: 10px 10px;
    flex-wrap: wrap;
  }
  .week-day-div {
    max-width: 150px;
    flex-grow: 1;
    border: #C8E2F1 solid 1px;
    border-right: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 100px;
    min-height: 90px;
    margin-bottom: 3px;
  }
  .week-day-0 {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .week-day-6 {
    border-right: #C8E2F1 solid 1px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .week-day-date {
    font-size: 15px;
    font-weight: bold;
    align-items: center;
    border-bottom: #C8E2F1 solid 1px;;
  }
  .money-event {
    font-size: 14px;
    padding: 4px 4px;
    margin-bottom: 4px;
    border-radius: 8px;
  }
  .event-items {
    display: flex;
    flex-direction: column;
    padding: 3px;
  }
  .event-container {
    display: flex;
    flex-direction: column;
  }
  .event-row1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .event-row2 {
    font-size: 11px;
  }
  .event-income {
    background-color: var(--color-income-box);
  }
  .event-expense {
    background-color: var(--color-expense-box);
  }
  .event-transfer {
    background-color: var(--color-transfer-box);
  }
  .event-asset_income {
    background: var(--color-income-box);
    background: linear-gradient(90deg, var(--color-income-box) 50%, var(--color-asset-box) 60%);
  }
  .event-asset_expense {
    background: var(--color-expense-box);
    background: linear-gradient(90deg, var(--color-expense-box) 50%, var(--color-asset-box) 60%);
  }
  .event-liability_income {
    background: var(--color-income-box);
    background: linear-gradient(90deg, var(--color-income-box) 50%, var(--color-liability-box) 60%);
  }
  .event-liability_expense {
    background: var(--color-expense-box);
    background: linear-gradient(90deg, var(--color-expense-box) 50%, var(--color-liability-box) 60%);
  }
}

@media only screen and (min-width: 760px) {

}

</style>