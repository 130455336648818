import {createRouter, createWebHistory} from "vue-router";

import Home from "./pages/home.vue";
// import About from "./pages/about.vue";
import Dashboard from "./dashboard/dashboard.vue";
import Feedback from "./pages/feedback.vue";
import FeedbackThankyou from "./pages/feedbackThankyou.vue";
import About from "./pages/about.vue";
import Contact from "./pages/contact.vue";
import TermsConditions from "@/pages/termsConditions.vue";
import PrivacyPolicy from "@/pages/privacyPolicy.vue";
import Pricing from "./pages/pricing.vue";
import AfterInactiveSignUp from "./devise/afterInactiveSignUp.vue";
import Learn from "./learn/learn.vue";
import GettingStarted from "@/learn/gettingStarted.vue";
import BuyHouse from "@/learn/buyHouse.vue";
import investOrMortgage from "@/learn/investOrMortgage.vue";
import Profile from "./pages/profile.vue";
import CurrentFinancialPosition from "./pages/currentFinancialPosition.vue";
import Assets from "./assets/assets.vue";
import Liabilities from "./liabilities/liabilities.vue";
import AddEditAssetLiability from "./assets/addEditAssetLiability.vue";
import AssetIncomeExpense from "@/assets/assetIncomeExpense.vue";
import Incomes from "./incomes/incomes.vue";
import Expenses from "./expenses/expenses.vue";
import AddEditIncomeExpense from "@/incomes/addEditIncomeExpense.vue";
import Transfers from "@/transfers/transfers.vue";
import AddEditTransfer from "@/transfers/addEditTransfer.vue";
import Scenarios from "@/scenarios/scenarios.vue";
import AddEditScenario from "@/scenarios/addEditScenario.vue";
import AssetChange from "@/assets/assetChange.vue";
import AssetIncome from "@/assets/assetIncome.vue";
import AssetExpense from "@/assets/assetExpense.vue";
import AssetIncomeChange from "@/assets/assetIncomeChange.vue"
import IncomeChange from "@/incomes/incomeChange.vue";
import TransferChange from "@/transfers/transferChange.vue";
import Reports from "@/reports/reports.vue";
import FutureForecastReport from "@/reports/futureForecastReport.vue";
import IncomeExpenseReport from "@/reports/incomeExpenseReport.vue";
import NetWorthReport from "@/reports/netWorthReport.vue";
import AssetBalancesReport from "@/reports/assetBalancesReport.vue";
// ------- Admin
import AdminDashboard from "@/admin/dashboard.vue"
import InvestOrMortgage from "@/learn/investOrMortgage.vue";

const routes = [
  { path: '/', name: 'Home', component: Home, meta: { title: 'Home', pagetitle: "Home" }},
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { title: 'Dashboard', pagetitle: "Dashboard" }},
  { path: '/about', name: 'About', component: About, meta: { title: 'About', pagetitle: "About" }},
  { path: '/contact', name: 'Contact', component: Contact, meta: { title: 'Contact', pagetitle: "Contact" }},
  { path: '/termsConditions', name: 'TermsConditions', component: TermsConditions, meta: { title: 'Terms and Conditions', pagetitle: "Terms and Conditions" }},
  { path: '/privacyPolicy', name: 'Privacy Policy', component: PrivacyPolicy, meta: { title: 'Privacy Policy', pagetitle: "Privacy Policy" }},
  { path: '/pricing', name: 'Pricing', component: Pricing, meta: { title: 'Pricing', pagetitle: "Pricing" }},
  { path: '/afterSignup', name: 'AfterSignup', component: AfterInactiveSignUp, meta: { title: 'After Signup', pagetitle: "After Signup" }},
  { path: '/afterInactiveSignup', name: 'AfterInactiveSignUp', component: AfterInactiveSignUp, meta: { title: 'After Inactive Signup', pagetitle: "After Inactive Signup" }},
  // { path: '/feedback', name: 'Feedback', component: Feedback, meta: { title: 'Feedback', pagetitle: "Feedback" }},
  // { path: '/feedbackThankyou', name: 'FeedbackThankyou', component: FeedbackThankyou},

  { path: '/learn', name: 'Learn', component: Learn},
  { path: '/learn/getting-started', name: 'GettingStarted', component: GettingStarted},
  { path: '/learn/keep-renting-vs-buy-house', name: 'BuyHouse', component: BuyHouse},
  { path: '/learn/pay-off-mortgage-or-invest', name: 'InvestOrMortgage', component: InvestOrMortgage},

  { path: '/user_profile', name: 'Profile', component: Profile},
  { path: '/currentFinancialPosition', name: 'CurrentFinancialPosition', component: CurrentFinancialPosition},

  { path: '/assets', name: 'Assets', component: Assets},
  { path: '/liabilities', name: 'Liabilities', component: Liabilities},
  { path: '/addEditAsset', name: 'AddAsset', component: AddEditAssetLiability},
  { path: '/addEditAsset/:id', name: 'EditAsset', component: AddEditAssetLiability},
  { path: '/addEditLiability', name: 'AddLiability', component: AddEditAssetLiability},
  { path: '/addEditLiability/:id', name: 'EditLiability', component: AddEditAssetLiability},
  { path: '/assetIncomeExpense', name: 'AssetIncomeExpense', component: AssetIncomeExpense},
  { path: '/incomes', name: 'Incomes', component: Incomes},
  { path: '/expenses', name: 'Expenses', component: Expenses},
  { path: '/addEditIncome', name: 'AddIncome', component: AddEditIncomeExpense},
  { path: '/addEditIncome/:id', name: 'EditIncome', component: AddEditIncomeExpense},
  { path: '/addEditExpense', name: 'AddExpense', component: AddEditIncomeExpense},
  { path: '/addEditExpense/:id', name: 'EditExpense', component: AddEditIncomeExpense},
  { path: '/transfers', name: 'Transfers', component: Transfers},
  { path: '/addEditTransfer', name: 'AddTransfer', component: AddEditTransfer},
  { path: '/addEditTransfer/:id', name: 'EditTransfer', component: AddEditTransfer},
  { path: '/scenarios', name: 'Scenarios', component: Scenarios},
  { path: '/addEditScenario', name: 'AddScenario', component: AddEditScenario},
  { path: '/addEditScenario/:id', name: 'EditScenario', component: AddEditScenario},
  { path: '/scenarioAssetChange/:scenarioId/:assetId', name: 'AddScenarioAssetChange', component: AssetChange},
  { path: '/scenarioAssetChange/:scenarioId/:assetId/:id', name: 'EditScenarioAssetChange', component: AssetChange},
  { path: '/scenarioLiabilityChange/:scenarioId/:liabilityId', name: 'AddScenarioLiabilityChange', component: AssetChange},
  { path: '/scenarioLiabilityChange/:scenarioId/:liabilityId/:id', name: 'EditScenarioLiabilityChange', component: AssetChange},
  { path: '/scenarioAssetIncome/:scenarioId/:assetId', name: 'AddScenarioAssetIncome', component: AssetIncome},
  { path: '/scenarioAssetIncome/:scenarioId/:assetId/:id', name: 'EditScenarioAssetIncome', component: AssetIncome},
  { path: '/scenarioLiabilityIncome/:scenarioId/:liabilityId', name: 'AddScenarioLiabilityIncome', component: AssetIncome},
  { path: '/scenarioLiabilityIncome/:scenarioId/:liabilityId/:id', name: 'EditScenarioLiabilityIncome', component: AssetIncome},
  { path: '/scenarioAssetExpense/:scenarioId/:assetId', name: 'AddScenarioAssetExpense', component: AssetExpense},
  { path: '/scenarioAssetExpense/:scenarioId/:assetId/:id', name: 'EditScenarioAssetExpense', component: AssetExpense},
  { path: '/scenarioLiabilityExpense/:scenarioId/:liabilityId', name: 'AddScenarioLiabilityExpense', component: AssetExpense},
  { path: '/scenarioLiabilityExpense/:scenarioId/:liabilityId/:id', name: 'EditScenarioLiabilityExpense', component: AssetExpense},

  { path: '/scenarioAssetIncomeChange/:scenarioId/:assetId/:incomeId', name: 'AddScenarioAssetIncomeChange', component: AssetIncomeChange},
  { path: '/scenarioAssetExpenseChange/:scenarioId/:assetId/:expenseId', name: 'AddScenarioAssetExpenseChange', component: AssetIncomeChange},
  { path: '/scenarioLiabilityIncomeChange/:scenarioId/:liabilityId/:incomeId', name: 'AddScenarioLiabilityIncomeChange', component: AssetIncomeChange},
  { path: '/scenarioLiabilityExpenseChange/:scenarioId/:liabilityId/:expenseId', name: 'AddScenarioLiabilityExpenseChange', component: AssetIncomeChange},

  { path: '/scenarioIncomeChange/:scenarioId/:incomeId', name: 'AddScenarioIncomeChange', component: IncomeChange},
  { path: '/scenarioIncomeChange/:scenarioId/:incomeId/:id', name: 'EditScenarioIncomeChange', component: IncomeChange},
  { path: '/scenarioExpenseChange/:scenarioId/:expenseId', name: 'AddScenarioExpenseChange', component: IncomeChange},
  { path: '/scenarioExpenseChange/:scenarioId/:expenseId/:id', name: 'EditScenarioExpenseChange', component: IncomeChange},
  { path: '/scenarioTransferChange/:scenarioId/:transferId', name: 'AddScenarioTransferChange', component: TransferChange},
  { path: '/scenarioTransferChange/:scenarioId/:transferId/:id', name: 'EditScenarioTransferChange', component: TransferChange},

  { path: '/reports', name: 'Reports', component: Reports},
  { path: '/report/future-forecast-report', name: 'FutureForecastReport', component: FutureForecastReport},
  { path: '/report/income-expense-report', name: 'IncomeExpenseReport', component: IncomeExpenseReport},
  { path: '/report/net-worth-report', name: 'NetWorthReport', component: NetWorthReport},
  { path: '/report/asset-balance-report', name: 'AssetBalanceReport', component: AssetBalancesReport},

  // ------- Admin
  { path: '/admin/dashboard', name: 'AdminDashboard', component: AdminDashboard},
]

const scrollBehavior = function () {
  return { top: 0, left: 0 }
}

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
})

export default router
